<!-- =========================================================================================
    File Name: tableFilterSorter.vue
    Description: Add filter and sorting functionality to table
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
    <vx-card>
      <h3 v-if="add_new" style="position: relative;bottom: 9px;">Add Story</h3>
      <h3 v-if="add_update" style="position: relative;bottom: 9px;">Edit Story</h3>

      <!-- Content Row -->
      <div class="vx-row">
        <div class="vx-col md:w-1/2 w-full">
          
          <vs-input class="w-full mt-4 required"  :maxlength=40 label="Title" placeholder="Title" v-model="formData['title']" v-validate="'required'" name="title" />
          <span class="text-danger text-sm"  v-show="errors.has('title')">{{ errors.first('title') }}</span>

          <div class="mt-4">
            <label class="text-sm " style="margin-left: 4px;">Module Name <span class="text-danger">*</span></label>
            <v-select class="w-full select-large" placeholder= "Module Name"  label="module_name" value="module_id" v-model="module_name_model" :clearable="false" :options="modules" v-validate="'required'" name="module_id" />
            <span class="text-danger text-sm"  v-show="errors.has('module_id')">{{ errors.first('module_id') }}</span>
          </div>

          <div class="mt-4">
          <label class="text-sm" style="margin-left: 4px;">Category Name <span class="text-danger">*</span></label>
            <v-select class="w-full select-large" placeholder= "Category Name"  label="category_name" value="category_id" v-model="category_name_model" :clearable="false" :options="main_categories" v-validate="'required'" name="category_id" />
            <span class="text-danger text-sm"  v-show="errors.has('category_id')">{{ errors.first('category_id') }}</span>
          </div>

         <!-- <div class="mt-4">
          <label class="text-sm" style="margin-left: 4px;">Sub Category Name</label>
            <v-select class="w-full select-large" placeholder= "Sub Category Name" label="category_name" value="category_id" v-model="sub_category_name_model" :clearable="false" :options="sub_categories" name="sub_category_id" />
            <span class="text-danger text-sm"  v-show="errors.has('sub_category_id')">{{ errors.first('sub_category_id') }}</span>
          </div> -->

           <div class="mt-4">
                <label class="text-sm" style="margin-left: 4px;">Sub Category Name</label>
                <select class="w-full select-large custom_select"  v-model="formData['sub_category_id']"  @change="onChange($event)">
                  <option value="0"></option>
                  <option v-for="sub_categories in sub_categories" name="sub_category_id" v-bind:value="sub_categories.category_id">{{ sub_categories.category_name }}</option>
               </select>
          </div>

          <div class="mt-4">
          <label class="text-sm" style="margin-left: 4px;">Tags</label>
            <v-select class="w-full select-large" placeholder= "Tags" label="tag_name" value="tag_id" v-model="tags_model" multiple :clearable="false" :options="tags" v-validate="'required'" name="media_tag_id" />
          </div>
          <div class="mt-4" hidden>
          <label class="text-sm" style="margin-left: 4px;">Short Description <span class="text-danger">*</span></label>
            <vs-textarea rows="2" v-validate="'required'" name="short_desc"  @keypress="iswordCountCheckShortdesc($event)"  v-model="formData['short_desc']" placeholder="Word count can not exceed more then 100..!!" />
            <span class="text-danger text-sm"  v-show="errors.has('short_desc')">{{ errors.first('short_desc') }}</span>
          </div>

          <div class="mt-4">
          <label class="text-sm" style="margin-left: 4px;">Long Description <span class="text-danger">*</span></label>
            <vs-textarea rows="4" v-validate="'required'"  @keypress="iswordCountCheckLongdesc($event)" name="long_desc" v-model="formData['long_desc']" placeholder="Word count can not exceed more then 100..!!" />
            <span class="text-danger text-sm" v-show="errors.has('long_desc')">{{ errors.first('long_desc') }}</span>
          </div>
        <div class="mt-4">
          <label class="text-sm" style="margin-left: 4px;">Benefits <span class="text-danger">*</span></label>
            <vs-textarea rows="4" v-validate="'required'" maxlength="240" name="benefits" v-model="formData['benefits']" placeholder="Benefits..." />
            <span class="text-danger text-sm" v-show="errors.has('benefits')">{{ errors.first('benefits') }}</span>
          </div>
        </div>

        <div class="vx-col md:w-1/2 w-full">
          <vs-input class="w-full mt-4 required" id="media_duration" label="Story Duration (mm:ss) " placeholder="Story Duration (mm:ss)" v-model="formData['media_duration']" @keypress="isNumber($event)"  v-validate="'required'" name="media_duration" />
            <span class="text-danger text-sm" v-if="errShow">Please Enter Story Duration In mm:ss Format..!! </span>

          <div class="mt-4">
            <label class="text-sm" style="margin-left: 4px;">Story Type  <span class="text-danger">*</span></label><br>
            <vs-radio v-model="formData['media_type']" v-if="enableMusic" name="media_type" vs-value="2" style="width:100px">Music </vs-radio>
            <vs-radio v-model="formData['media_type']" v-if="enableVido" name="media_type" vs-value="1" >Video </vs-radio>
          </div>

       <div class="vx-row mt-4">
            <div class="vx-col w-full">
              <div class="flex items-start flex-col sm:flex-row profile_camera_icon thumb_icon">
               <div class="new_file_upld">
            <label class="text-sm" style="margin-left: 4px;">Recommendation<span class="text-danger">*</span></label><br>

                <img :src="formData['recmd_url']||'/1090x595.png'" class="rounded h-32 w-32" />
                 <div class="blt_buttons" v-if="add_thumbnail">
                  <input type="file" class="hidden" ref="update_recmd" @change="update_recmd" accept=".png,.jpg,.jpeg">
                  <vs-button class="" v-if="add_thumbnail" @click="$refs.update_recmd.click()"><feather-icon icon="CameraIcon" class="inline-block" svgClasses="w-4 h-4" /></vs-button>
                </div>

                <div class="blt_buttons" v-if="remove_thumbnails" >
                  <input type="file" class="hidden" ref="update_thumb" @change="remove_thumbnail" accept=".png,.jpg,.jpeg">
                  <vs-button type="border" class=""  color="danger" v-if="remove_thumbnails" @click="$refs.update_thumb.click()"><feather-icon icon="CameraIcon" class="inline-block" svgClasses="w-4 h-4" /></vs-button>
                </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Avatar Row -->
          <div class="vx-row mt-4">
            <div class="vx-col w-full">
              <div class="flex items-start flex-col sm:flex-row profile_camera_icon thumb_icon">
              
               <div class="new_file_upld">
            <label class="text-sm" style="margin-left: 4px;">Thumbnail<span class="text-danger">*</span></label><br>

                <img :src="formData['thumb_url']||'/400x400.png'" class="rounded h-32 w-32" />
                <!-- <vs-avatar :src="data.avatar" size="80px" class="mr-4" /> -->
                 <div class="blt_buttons" v-if="add_thumbnail">
                  <input type="file" class="hidden" ref="update_thumb" @change="update_thumbnail" accept=".png,.jpg,.jpeg">
                  <vs-button class="" v-if="add_thumbnail" @click="$refs.update_thumb.click()"><feather-icon icon="CameraIcon" class="inline-block" svgClasses="w-4 h-4" /></vs-button>
                </div>

                <div class="blt_buttons" v-if="remove_thumbnails" >
                  <input type="file" class="hidden" ref="update_thumb" @change="remove_thumbnail" accept=".png,.jpg,.jpeg">
                  <vs-button type="border" class=""  color="danger" v-if="remove_thumbnails" @click="$refs.update_thumb.click()"><feather-icon icon="CameraIcon" class="inline-block" svgClasses="w-4 h-4" /></vs-button>
                </div>
                </div>
              </div>
            </div>
          </div>
          

          <div class="mt-4" v-if="upload_medias">
            <label class="text-sm" style="margin-left: 4px;">Upload Story</label><br>
            <input type="file" ref="du" class="hidden" @change="upload_media" accept=".mp4,.wmv">            
            <vs-button size="small" type="border" @click="$refs.du.click()">
              <feather-icon icon="UploadIcon" svgClasses="h-4 w-4" />
              <span class="ml-2 text-base text-primary">Upload</span>
            </vs-button>   
          </div>

          <div class="mt-4" v-if="remove_medias">
            <label class="text-sm" style="margin-left: 4px; color: red;">Remove Story</label><br>
            <input type="file" ref="du" class="hidden" @change="remove_media" accept=".mp4,.wmv">
            <vs-button size="small" type="border" color="danger" @click="$refs.du.click()">
              <feather-icon icon="UploadIcon" svgClasses="h-4 w-4" />
              <span class="ml-2 text-base text-primary" style="color:red !important;">Replace</span>
            </vs-button>
          </div>
          <div class="mt-4">
            <label class="text-sm" style="margin-left: 4px;">Uploaded Story Url</label><br>
            <vs-chip class="uploaded_media_url">{{formData['media_url']}}</vs-chip>
          </div><br>

          <div class="mt-4">
            <label class="text-sm" style="margin-left: 4px;">Status</label>
            <vs-switch color="success" v-model="status_model">
                <span slot="on">Active</span>
                <span slot="off">InActive</span>
            </vs-switch>
          </div>
        </div>
      </div>

      <!-- Save & Reset Button -->
      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="mt-8  flex-wrap items-center justify-end">
            <vs-button class="ml-auto mt-2" @click="save_changes" :disabled="!validateForm">Save Changes</vs-button>
            <vs-button class="ml-4 mt-2" v-if="reset_btn"  color="warning" @click="reset_data">Reset</vs-button>
            <vs-button class="ml-4 mt-2" v-if="canel_btn" type="border" color="warning" @click="cancel_data">Cancel</vs-button>
           </div>
        </div>
      </div>

    </vx-card>
</template>

<script>
// import moduleMedia          from '@/store/media/moduleMedia.js'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'

export default {
  data () {
    return {
       promptName    :'Edit Profile',
       formData      :{},
       interests     :[],
       add_thumbnail: true,
       remove_thumbnails : '',
       upload_medias:true,
       remove_medias:'',
       enableVido:true,
       enableMusic:false,
       upload_media_url:0,
       upload_thumb_url:0,
       add_new : true,
       add_update :false,
       reset_btn : true,
       canel_btn : false,
       errImageupload:''
       ,add_recmdnail: true, remove_recmdnails : '',
       errShow : false
    }
  },
  components: {
    vSelect,
    flatPickr
  },
  computed: {
    media ()     { 
      if(!this.$route.params.id){
        return {sub_category_id:"0",'recmd_url':'/1090x595.png','upvote_count':'0','downvote_count':'0','view_count':'0','country_id' : this.$store.state.AppActiveUser.country_id,'program_id' : '0',org_id:this.$store.state.AppActiveUser.org_id,'status':"0",'media_url':'','thumb_url':'/400x400.png',"module_id":"","category_id":"","media_tag_id":"","media_type":"1"};
      }else{
        if(this.formData.thumb_url != ""){
            this.remove_thumbnails = true;
            this.add_thumbnail = false;
            this.upload_medias = false;
            this.remove_medias = true;
             this.remove_recmdnails = true;
            this.add_recmdnail = false;
            this.upload_thumb_url = 1
            this.upload_media_url = 1
            this.recmd_url = 1
            this.add_new = false
            this.add_update = true
             this.reset_btn = false
            this.canel_btn = true
            this.errShow = false

        }
        return this.$store.getters['media/getMedia'](this.$route.params.id)
      }
    },
    tags () { 
      return this.$store.getters['tag/getActiveTags']
    },   
    modules () { 
      return this.$store.getters['module/getActiveModules_media']
    },
    categories ()     { 
      return this.$store.getters['category/queriedCategories']      
    },
    main_categories () { 
      return this.$store.getters['category/getMainCategories_media'](this.formData['module_id'])
    },
    sub_categories () { 
       const subCat = this.$store.getters['category/getSubCategories'](this.formData['category_id'])
      if(subCat.length > 0){
        const subcatvalue =subCat[0]['category_id']
       localStorage.setItem('subcatvalue',subcatvalue)
      }
      return subCat
    },
    modules () { 
      return this.$store.getters['module/getActiveModules_media']
    },
    categories () { 
      return this.$store.getters['category/queriedCategories']
    },
    status_model: {
      get () {
        return this.formData['status'] === '1'
      },
      set (status) {
        this.formData['status'] = status ? '1' : '0'
      }
    },
    module_name_model: {
      get () {
        return this.$store.getters['module/getModule'](this.formData['module_id'])
      },
      set (module_name) {
        this.formData['category_id'] = ''
        this.formData['module_id'] = module_name['module_id']
      }
    },
    category_name_model: {
      get () {
        return this.$store.getters['category/getCategory'](this.formData['category_id'])
      },
      set (category_name) {
        this.formData['sub_category_id'] = ''

        this.formData['category_id'] = category_name['category_id']
      }
    },
    sub_category_name_model: {
      get () {
        return this.$store.getters['category/getCategory'](this.formData['sub_category_id'])
      },
      set (category_name) {
         localStorage.setItem('subcatvalue',category_name['category_id'])
        this.formData['sub_category_id'] = category_name['category_id']
      }
    },
   
    tags_model: {
      get () {
        const tagId = this.formData['media_tag_id'].toString()
        return this.$store.getters['tag/getSelectedTags'](tagId||'')
      },
      set (tags) {
        let tmp = [];
        for (var i = 0; i < tags.length; i++) {
          tmp.push(tags[i].tag_id);
        };
        this.formData['media_tag_id'] = tmp //.join(',');
      }
    },
    validateForm () {
      return !this.errors.any() && this.errShow == 0 && this.formData.title !== '' && this.formData.title && this.formData.module_id !== '' && this.formData.module_id
      
      //&& this.formData.module_id !== '' && this.formData.category_id !== ''  && this.formData.media_tag_id !== '' && this.formData.short_desc == '' && this.formData.long_desc !== '' && this.formData.media_duration !== ''  && this.formData.title && this.formData.module_id && this.formData.category_id && this.formData.media_tag_id && this.formData.short_desc && this.formData.long_desc && this.formData.media_duration
    }
  },
  methods: {
     iswordCountCheckShortdesc: function(evt) {
      var len = this.formData['short_desc'].split(/[\s]+/);
      if(len.length > 100){
        this.errShortdesc = true
         evt.preventDefault();
      }else{
        this.errShortdesc = false
      }
    },
    iswordCountCheckLongdesc: function(evt) {
      var len = this.formData['long_desc'].split(/[\s]+/);
      if(len.length > 100){
        this.errLongdesc = true
         evt.preventDefault();
      }else{
        this.errLongdesc = false
      }
    },
      onChange(event) {
      localStorage.setItem('subcatvalue',event.target.value)
    },
       isNumber: function($event) {
          var isValid = /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(this.formData['media_duration']);
          if(isValid == false){
               this.errShow = true
          }else{
              this.errShow = false
          }
           if((this.formData['media_duration']).length == 4){
            this.errShow = false
          }
         let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
      // only allow number and one dot
       if ((keyCode < 48 || keyCode > 57) && (keyCode !== 58 || this.formData['media_duration'].indexOf(':') != -1)) { // 46 is dot
        $event.preventDefault();
       }

       // restrict to 2 decimal places
       if(this.formData['media_duration']!=null && this.formData['media_duration'].indexOf(":")>-1  && keyCode != 58 && (this.formData['media_duration'].split(':')[1].length > 1)){
       $event.preventDefault();
       }
    },
    isNumber1: function($event) {
        let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
      // only allow number and one dot
       if ((keyCode < 48 || keyCode > 57) && (keyCode !== 46 || this.formData['media_duration'].indexOf('.') != -1)) { // 46 is dot
        $event.preventDefault();
       }

       // restrict to 2 decimal places
       if(this.formData['media_duration']!=null && this.formData['media_duration'].indexOf(".")>-1 && (this.formData['media_duration'].split('.')[1].length > 1)){
       $event.preventDefault();
       }
    },
     cancel_data(){
        this.$router.push('/story/all/').catch(() => {})
    },
    save_changes () {
      if( this.formData['sub_category_id'] == ''){
        this.formData['sub_category_id'] = 0
      }
      // if (!this.validateForm) 
       this.$vs.loading()
       if (localStorage["subcatvalue"]) {
        this.formData['sub_category_id'] = localStorage.getItem('subcatvalue')
       }
            if(this.formData['sub_category_id'] == "undefined" || this.formData['sub_category_id'] == undefined){
              this.formData['sub_category_id'] = 0
            }
      if(!this.$route.params.id){
        this.$validator.validateAll().then(result => {
         // if (result) {
           this.formData['media_tag_id'] = this.formData['media_tag_id'].toString()
            this.formData['country_id']   = this.formData['country_id'].toString()
            
            this.$store.dispatch('media/addMedia', Object.assign({}, this.formData)).then((res) => {
              localStorage.removeItem("subcatvalue");
               this.$store.dispatch('media/fetchMedias')
              this.$vs.loading.close()
              const success = 'Story added successfully...!!!'
              this.notif_sucess(success) 
            }).catch((error) => {
                 if( error.message == "Request failed with status code 423"){
                          error.message = 'Story is created by same name..!!!'
                      }else{
                          error = error.message
                      } 
                this.$vs.loading.close()
                this.notif(error)     
            })
          //}
        })
      }else{
        this.$validator.validateAll().then(result => {
       //   if (result) {
         this.formData['media_tag_id'] = this.formData['media_tag_id'].toString()
            this.formData['country_id']   = this.formData['country_id'].toString()
            this.$store.dispatch('media/updateMedia', Object.assign({}, this.formData)).then((res) => {
              localStorage.removeItem("subcatvalue");
               this.$store.dispatch('media/fetchMedias')
                this.$vs.loading.close()
                const success = 'Story Updated Successfully...!!!'
                this.notif_sucess(success) 
              }).catch((error) => { 
                this.$vs.loading.close()
                this.notif(error)     
              })
         // }
        })
      }
    },
    notif (error) {
      this.$vs.notify({
        title: 'Alert',
        text: error.message,
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'danger'
      })
    },
    notif_sucess (success) {
      this.$vs.notify({
        title: 'success',
        text: success,
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color:"success"
      })
    },
    reset_data () {
      this.formData = Object.assign({}, this.media)
      this.add_recmdnail = true;
        this.remove_recmdnails = false;
        this.add_thumbnail = true;
        this.remove_thumbnails = false;
        this.upload_thumb_url = 0
        this.upload_medias = true;
        this.remove_medias = false;
        this.upload_media_url = 0
    },
     notif_failed (error) {
      this.$vs.notify({
        title: 'Alert',
        text: error,
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color:"danger"
      })
    },
     update_thumbnail (e) {
      this.$vs.loading()
                let file = e.target.files[0];
                let reader = new FileReader();
                const fileSize = file.size
                var sizeInMB = (fileSize / (1024*1024)).toFixed(2);
                reader.readAsDataURL(file);
                reader.onload = e => {
                    const src = e.target.result;
                    const img = new Image();
                    img.onload = () => {
                     if((img.height !== 400 || img.width !== 400) ){
                            this.$refs.update_thumb.value = ''
                            this.errImageupload = 1
                            this.$vs.loading.close() 
                            const error = "The uploaded image size should be below 2 MB and its resolution should be 400*400 pixel..!!"
                            this.notif_failed(error)
                      }else{
                            this.errImageupload = 0
                      }
                    };
                    img.src = src;
                };
    setTimeout(() => {
      if(this.errImageupload == 0){
      this.$store.dispatch('media/uploadImage', {image:e.target.files[0], media_id:this.formData.media_id}).then((res) => {
        this.formData['thumb_url'] = res
        this.remove_thumbnails = true;
        this.upload_thumb_url = 1
        this.add_thumbnail = false;
        this.$refs.update_thumb.value = ''
        this.$vs.loading.close()
      }).catch((error) => { 
                  this.$vs.loading.close()
                  this.notif(error)     
         })
      }
    }, 200);
  },
   
     remove_thumbnail(e) {
       this.$vs.loading()
                let file = e.target.files[0];
                let reader = new FileReader();
                const fileSize = file.size
                var sizeInMB = (fileSize / (1024*1024)).toFixed(2);
                reader.readAsDataURL(file);
                reader.onload = e => {
                    const src = e.target.result;
                    const img = new Image();
                    img.onload = () => {
                      if((img.height !== 400 || img.width !== 400) ){
                            this.$refs.update_thumb.value = ''
                            this.errImageupload = 1
                            this.$vs.loading.close() 
                            const error = "The uploaded image size should be below 2 MB and its resolution should be 400*400 pixel..!!"
                            this.notif_failed(error)
                      }else{
                            this.errImageupload = 0
                      }
                    };
                    img.src = src;
                };
    setTimeout(() => {
      if(this.errImageupload == 0){
          this.$store.dispatch('media/removeImage', {image:e.target.files[0], media_id:this.formData.media_id,oldImage:this.formData['thumb_url']}).then((res)=>{
           this.formData['thumb_url'] = res
            this.remove_thumbnails = true;
            this.upload_thumb_url = 1
            this.add_thumbnail = false;
            this.$refs.update_thumb.value = ''
            this.$vs.loading.close()
          }).catch((error) => { 
                      this.$vs.loading.close()
                      this.notif(error)     
            })
          }
    }, 200);
    },
      update_recmd (e) {
      this.$vs.loading()
                let file = e.target.files[0];
                let reader = new FileReader();
                const fileSize = file.size
                var sizeInMB = (fileSize / (1024*1024)).toFixed(2);
                reader.readAsDataURL(file);
                reader.onload = e => {
                    const src = e.target.result;
                    const img = new Image();
                    img.onload = () => {
                        if((img.height !== 595 || img.width !== 1090)){
                     //   if(sizeInMB > 2)
                     //  {
                            this.errImageupload = 1
                            this.$refs.update_recmd.value = ''
                            this.$vs.loading.close() 
                            const error = "The uploaded image size should be below 2 MB and its resolution should be 595*1090 pixel..!!"
                            this.notif_failed(error)
                      }else{
                            this.errImageupload = 0
                      }
                    };
                    img.src = src;
                };
    setTimeout(() => {
      if(this.errImageupload == 0){
      this.$store.dispatch('media/uploadrecmdImage', {image:e.target.files[0], media_id:this.formData.media_id}).then((res) => {
        this.formData['recmd_url'] = res
        this.remove_recmdnails = true;
        this.recmd_url = 1
        this.add_recmdnail = false;
        this.$refs.update_recmd.value = ''
        this.$vs.loading.close()
      }).catch((error) => { 
                  this.$vs.loading.close()
                  this.notif(error)     
         })
      }
    }, 200);
  },
    remove_recmdnail(e) {
       this.$vs.loading()
                let file = e.target.files[0];
                let reader = new FileReader();
                const fileSize = file.size
                var sizeInMB = (fileSize / (1024*1024)).toFixed(2);
                reader.readAsDataURL(file);
                reader.onload = e => {
                    const src = e.target.result;
                    const img = new Image();
                    img.onload = () => {
                     if((img.height !== 595 || img.width !== 1090)){
                      // if(sizeInMB > 2)
                     //  {
                            this.errImageupload = 1
                            this.$refs.remove_recmd.value = ''
                            this.$vs.loading.close() 
                            const error = "The uploaded image size should be below 2 MB and its resolution should be 595*1090 pixel..!!"
                            this.notif_failed(error)
                      }else{   
                            this.errImageupload = 0
                      }
                    };
                    img.src = src;
                };
              setTimeout(() => {
                  if(this.errImageupload == 0){
                      this.$store.dispatch('media/removeImage', {image:e.target.files[0], media_id:this.formData.media_id,oldImage:this.formData['thumb_url']}).then((res)=>{
                        this.add_recmdnail = false;
                        this.$refs.remove_recmd.value = ''
                        this.formData['recmd_url'] = res
                        this.remove_recmdnails = true;
                        this.recmd_url = 1
                        this.$vs.loading.close()
                      }).catch((error) => { 
                                  this.$vs.loading.close()
                                  this.notif(error)     
                        })
                    }
              }, 200);
    },
    upload_media (e) {
       let file = e.target.files[0];
       const fileSize = file.size
      var sizeInMB = (fileSize / (1024*1024)).toFixed(2);
       if(sizeInMB > 500){
            this.$refs.du.value = ''
            this.$vs.loading.close()
            const error = "Maximum we can upload 500Mb size of media..!!"
            this.notif_failed(error)
       }else{
          this.$vs.loading()
          this.$store.dispatch('media/uploadMedia', {media:e.target.files[0], media_id:this.formData.media_id}).then((res) => {
            this.formData['media_url'] = res
            this.upload_medias = false;
            this.upload_media_url = 1
            this.remove_medias = true;
             this.$refs.du.value = ''
            this.$vs.loading.close()
          }).catch((error) => { 
                      this.$vs.loading.close()
                      this.notif(error)     
            })
            
       }
    },
     remove_media (e) {
       let file = e.target.files[0];
       const fileSize = file.size
      var sizeInMB = (fileSize / (1024*1024)).toFixed(2);
       if(sizeInMB > 500){
            this.$refs.du.value = ''
            this.$vs.loading.close()
            const error = "Maximum we can upload 500Mb size of media..!!"
            this.notif_failed(error)
       }else{
          this.$vs.loading()
        this.$store.dispatch('media/removeMedia', {media:e.target.files[0],image:this.formData['media_url'], media_id:this.formData.media_id}).then((res) => {
            this.formData['media_url'] = res
            this.upload_medias = false;
            this.upload_media_url = 1
            this.remove_medias = true;
             this.$refs.du.value = ''
            this.$vs.loading.close()
      }).catch((error) => { 
                  this.$vs.loading.close()
                  this.notif(error)     
         })
       }
    },    
  },
  created () {
    // this.$store.registerModule('media', moduleMedia)
    if (!this.media) {
      this.$store.dispatch('media/fetchMedias').then(() => {
        this.formData = Object.assign({}, this.media)
      }).catch((error) => this.notif(error))
    } else {
      this.formData = Object.assign({}, this.media)
    }
    if (this.modules.length === 0) {
      this.$store.dispatch('module/fetchModules')
    }
    if (this.categories.length === 0) {
      this.$store.dispatch('category/fetchCategories')
    }
    if (this.tags.length === 0) {
      this.$store.dispatch('tag/fetchTags')
    }
  },
  beforeDestroy () {
    // this.$store.unregisterModule('media')
  }
}
</script>
